import axios from 'axios'

// export const baseURL = 'https://www.anhuiyidaotong.com'
// export const baseAPIURL = 'https://www.anhuiyidaotong.com'

export const baseURL = 'http://116.62.178.98' // 116.62.178.98
export const baseAPIURL = baseURL

const request = axios.create({
  baseURL: baseAPIURL, // 所有请求的公共地址部分
  timeout: 60000000,
})

// 请求相关处理 请求拦截 在请求拦截中可以补充请求相关的配置
// interceptors axios的拦截器对象
request.interceptors.request.use(
  (config) => {
    // config 请求的所有信息
    // console.log(config);
    config.headers = {
      'X-Requested-With': 'XMLHttpRequest',
    }

    if (typeof config.data === 'object') {
      config.data = Object.assign(config.data, {
        apikey: localStorage.getItem('key') || '',
      })
    }

    if (!config.data) {
      const t = new FormData()
      t.append('area', localStorage.getItem('region') || '')
      t.append('apikey', localStorage.getItem('key') || '')
      config.data = t
    }

    if (dataType(config.data) === 'formdata') {
      if (!config.data.has('area')) {
        config.data.append('area', localStorage.getItem('region') || '')
      }
      if (!config.data.has('apikey')) {
        config.data.append('apikey', localStorage.getItem('key') || '')
      }
    }
    return config
  },
  (err) => {
    // 请求发生错误时的相关处理 抛出错误
    Promise.reject(err)
  }
)

request.interceptors.response.use(
  (res) => {
    // 我们一般在这里处理，请求成功后的错误状态码 例如状态码是500，404，403
    // res 是所有相应的信息
    // console.log(res)
    return Promise.resolve(res.data)
  },
  (err) => {
    // 服务器响应发生错误时的处理
    Promise.reject(err)
  }
)

function dataType(data) {
  return Object.prototype.toString.call(data).slice(8, -1).toLowerCase()
}

export default request
